import { FormInstance } from '@dltru/dfa-ui'
import { FC } from 'react'
import { CSSTransition, TransitionGroup } from 'react-transition-group'

import { AccountRefillInfo } from '@components/Modals/Orders/Trade/common/AccountRefillInfo'
import { LimitInfoBody } from '@components/Modals/Orders/Trade/common/LimitInfoBody'

import { TradeBuyingModalFormBody } from './TradeBuyingModalFormBody'

export interface ITradeBuyingModalBody {
    setIsBodyTarget: any
    isBodyTarget: string
    isAmount: number
    setIsAmount: (val: number) => void
    setIsModalVisible: (val: boolean) => void
    form: FormInstance<any>
}

export const TradeBuyingModalBody: FC<ITradeBuyingModalBody> = ({
    isAmount,
    setIsAmount,
    setIsBodyTarget,
    isBodyTarget,
    setIsModalVisible,
    form,
}) => {
    const changeBody = (target: string) => (e: React.SyntheticEvent) => {
        e.preventDefault()
        setIsBodyTarget(target)
    }

    return (
        <TransitionGroup className="slide-animation__block">
            {isBodyTarget === 'buying' && (
                <CSSTransition timeout={300} classNames="slide-animation">
                    <TradeBuyingModalFormBody
                        isAmount={isAmount}
                        setIsAmount={setIsAmount}
                        changeBody={changeBody}
                        setIsModalVisible={setIsModalVisible}
                        form={form}
                    />
                </CSSTransition>
            )}
            {isBodyTarget === 'info' && (
                <CSSTransition timeout={300} classNames="slide-animation">
                    <AccountRefillInfo changeBodyToBuying={changeBody('buying')} />
                </CSSTransition>
            )}
            {isBodyTarget === 'limit' && (
                <CSSTransition timeout={300} classNames="slide-animation">
                    <LimitInfoBody changeBodyToBuying={changeBody('buying')} />
                </CSSTransition>
            )}
        </TransitionGroup>
    )
}
