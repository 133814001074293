import { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { CardModal, Form, getModalTradeHeaderData, getRepurchasePercent } from '@dltru/dfa-ui'

import { loadDfaAvailableDeals } from '@store/dfa/availableDeals'
import { dfaAvailableDealsSelector } from '@store/dfa/availableDeals/selectors'
import { dfaDetailsSelector } from '@store/dfa/details/selectors'
import { loadsetAccountMoneyByUser } from '@store/money'
import { profileDetailsSlice } from '@store/profiles/details'
import { profileSelector } from '@store/profiles/details/selectors'

import { TradeBuyingModalBody } from './TradeBuyingModalBody'

interface ITradeBuyingModal {
    isModalVisible: boolean
    setIsModalVisible: (val: boolean) => void
    amount?: number
}

export const TradeBuyingModal: FC<ITradeBuyingModal> = ({
    isModalVisible,
    setIsModalVisible,
    amount,
}) => {
    const [isBodyTarget, setIsBodyTarget] = useState('buying')
    const [isAmount, setIsAmount] = useState(amount || 0)
    const reduxDispatch = useDispatch()
    const dfaDetails = useSelector(dfaDetailsSelector.selectDfaDetails)
    const available = useSelector(dfaAvailableDealsSelector.selectData)
    const isCheckQuota = useSelector(profileSelector.isCheckQuota)

    const [form] = Form.useForm()

    useEffect(() => {
        if (isModalVisible) {
            if (dfaDetails?.id !== undefined) {
                reduxDispatch(loadDfaAvailableDeals())
            }
            reduxDispatch(loadsetAccountMoneyByUser())
            if (isCheckQuota) {
                reduxDispatch(profileDetailsSlice.actions.getQualifyInfo())
            }
            if (isAmount) {
                form.setFieldsValue({ isAmount })
            }
        }
    }, [isModalVisible])

    const changeModalVisible = (val: boolean) => {
        setIsModalVisible(val)
        setIsBodyTarget('buying')
        setIsAmount(0)
        form.resetFields()
    }

    const repurchasePercent = getRepurchasePercent(dfaDetails.price_source_type, available)
    return (
        <CardModal
            isModalVisible={isModalVisible}
            setIsModalVisible={changeModalVisible}
            closable
            headerData={getModalTradeHeaderData(dfaDetails, repurchasePercent)}
            buying
            isEmission
            width={540}
            mini={isBodyTarget === 'approve'}
            BodyRenderProps={
                <TradeBuyingModalBody
                    isAmount={isAmount}
                    setIsAmount={setIsAmount}
                    isBodyTarget={isBodyTarget}
                    setIsBodyTarget={setIsBodyTarget}
                    setIsModalVisible={changeModalVisible}
                    form={form}
                />
            }
            header={''}
            body={''}
        />
    )
}
