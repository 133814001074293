import { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { getPennies } from '@dltru/dfa-models'
import { CardModal, getModalTradeHeaderData, getRepurchasePercent } from '@dltru/dfa-ui'

import { dfaAvailableDealsSelector } from '@store/dfa/availableDeals/selectors'
import { dfaDetailsSelector } from '@store/dfa/details/selectors'
import { postOrder, postOrderPrefly } from '@store/orders/details'
import { orderDetailsSelector } from '@store/orders/details/selectors'

import { TradeBuyingModalApproveBody } from './TradeBuyingModalApproveBody'

export interface ITradeBuyingApproveModal {
    isModalVisible: boolean
    setIsModalVisible: (val: boolean) => void
    setIsParentModalVisible: (val: boolean) => void
    amount: number
    dynamicPrice?: number
    fee?: number
    isDynamicPrice?: boolean
}

export const TradeBuyingApproveModal: FC<ITradeBuyingApproveModal> = ({
    isModalVisible,
    setIsModalVisible,
    setIsParentModalVisible,
    amount,
    dynamicPrice,
    fee,
    isDynamicPrice,
}) => {
    const closeModal = (val: boolean) => {
        setIsModalVisible(val)
    }

    const reduxDispatch = useDispatch()
    const dfaDetails = useSelector(dfaDetailsSelector.selectDfaDetails)
    const available = useSelector(dfaAvailableDealsSelector.selectData)
    const isLoading = useSelector(orderDetailsSelector.selectIsLoading)
    const [isStartLoading, setIsStartLoading] = useState(false)

    const postOrderData = (skid: string) => {
        let orderPrice = Number(amount) * 100

        if (isDynamicPrice && dynamicPrice) {
            const count = Math.trunc(getPennies(amount) / dynamicPrice)
            orderPrice = getPennies(count * (dynamicPrice / 100))
        }

        reduxDispatch(
            postOrderPrefly({
                asset_id: dfaDetails.id as number,
                order_price: orderPrice,
                skid,
                acquisition_fee_amount: getPennies(fee),
            }),
        )
    }

    const signTransaction = (skid: string) => {
        let orderPrice = Number(amount) * 100

        if (isDynamicPrice && dynamicPrice) {
            const count = Math.trunc(getPennies(amount) / dynamicPrice)
            orderPrice = getPennies(count * (dynamicPrice / 100))
        }

        reduxDispatch(
            postOrder({
                asset_id: dfaDetails.id as number,
                order_price: orderPrice,
                skid,
                acquisition_fee_amount: getPennies(fee),
            }),
        )
    }

    useEffect(() => {
        if (isLoading) {
            setIsStartLoading(true)
        } else if (isStartLoading && !isLoading) {
            setIsStartLoading(false)
            setIsModalVisible(false)
            setIsParentModalVisible(false)
        }
    }, [isLoading])

    const repurchasePercent = getRepurchasePercent(dfaDetails.price_source_type, available)

    return (
        <>
            <CardModal
                isModalVisible={isModalVisible}
                setIsModalVisible={closeModal}
                headerData={getModalTradeHeaderData(dfaDetails, repurchasePercent)}
                secondModal
                buying
                mini
                width={540}
                BodyRenderProps={
                    <TradeBuyingModalApproveBody
                        isAmount={amount}
                        dynamicPrice={dynamicPrice}
                        request={postOrderData}
                        closeModal={closeModal}
                        loading={isLoading}
                        fee={fee}
                        sign={signTransaction}
                    />
                }
            />
        </>
    )
}
