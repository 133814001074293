import { ISecondaryOrderShort, OrderTypeEnum } from '@dltru/dfa-models'
import { RevokeConfirmModal } from '@dltru/dfa-ui'
import { FC } from 'react'
import { useDispatch } from 'react-redux'
import { useMatch } from 'react-router-dom'

import { revokeSecondaryBuyDfa, revokeSecondarySellDfa } from '@store/secondaryDfa/details'
import { removeSecondaryDfaById } from '@store/secondaryDfa/list'

interface IComponentProps {
    isModalVisible: boolean
    setIsModalVisible: (val: boolean) => void
    setIsParentModalVisible?: (val: boolean) => void
    order?: ISecondaryOrderShort
}
export const SecondaryRevokeModal: FC<IComponentProps> = ({
    isModalVisible,
    setIsModalVisible,
    setIsParentModalVisible,
    order,
}) => {
    const reduxDispatch = useDispatch()
    const isMyOrders = Boolean(useMatch('/clients/:id'))

    const onRevokeHandler = () => {
        if (!order?.uuid || !order?.id) {
            return
        }

        const data = {
            order_uuid: order.uuid,
        }

        if (order?.order_type === OrderTypeEnum.buy) {
            reduxDispatch(revokeSecondaryBuyDfa({ data, withUpdateOrder: isMyOrders }))
        } else {
            reduxDispatch(revokeSecondarySellDfa({ data, withUpdateOrder: isMyOrders }))
        }
        if (!isMyOrders) {
            reduxDispatch(removeSecondaryDfaById(order.id))
        }

        setIsParentModalVisible?.(false)
        setIsModalVisible(false)
    }

    const onRevokeCancelHandler = () => {
        setIsModalVisible(false)
    }

    return (
        <RevokeConfirmModal
            isAdmin={false}
            type={order?.order_type}
            isModalVisible={isModalVisible}
            onOk={onRevokeHandler}
            onCancel={onRevokeCancelHandler}
        />
    )
}
