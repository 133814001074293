import { CertificateType, SettlementsType } from '@dltru/dfa-models'
import { BodyTitle, Box, Button, Space } from '@dltru/dfa-ui'
import { FC, useState } from 'react'
import { useSelector } from 'react-redux'

import { dfaDetailsSelector } from '@store/dfa/details/selectors'

import { ConditionTradeLink } from '@components/ConditionTradeLink'
import { OrderInfo } from '@components/Modals/Orders/Trade/Emission/TradeBuyingApproveModal/OrderInfo'
import { AccountLimitBlock } from '@components/Modals/Orders/Trade/common/AccountLimitBlock'
import { CertificateListModalWithTransactionData } from '@components/Modals/Certificate/CertificateListModalWithTransactionData'

interface ITradeBuyingModalApproveBody {
    isAmount: number
    dynamicPrice?: number
    request: (skid: string) => void
    closeModal: any
    loading: boolean
    fee?: number
    sign: any
}

export const TradeBuyingModalApproveBody: FC<ITradeBuyingModalApproveBody> = ({
    isAmount,
    dynamicPrice,
    request,
    closeModal,
    loading,
    fee,
    sign,
}) => {
    const dfaDetails = useSelector(dfaDetailsSelector.selectDfaDetails)
    const [isVisibleCertificateModal, setIsVisibleCertificateModal] = useState(false)
    const closeThisModal = (e: React.SyntheticEvent): void => {
        e.preventDefault()
        closeModal(false)
    }

    const onSubscribe = (certificate: CertificateType) => {
        request(certificate.skid)
    }

    const onSign = (certificate: CertificateType) => {
        sign(certificate.skid)
    }
    

    return (
        <div style={{ background: '#ffffff' }}>
            <BodyTitle title="Детали приобретения" />
            <OrderInfo amount={isAmount} dynamicPrice={dynamicPrice} fee={fee} />
            {dfaDetails.issue_settlements_type !== SettlementsType.direct && (
                <Box padding={[0, 0, 16, 0]}>
                    <AccountLimitBlock />
                </Box>
            )}
            <Box padding={[0, 0, 24, 0]} align="center">
                <p className="card-modal__body-text--12">
                    <ConditionTradeLink
                        isBuying
                        isEmissionDirect={
                            dfaDetails.issue_settlements_type === SettlementsType.direct
                        }
                    />
                </p>
            </Box>
            <Box padding={[24, 0, 0, 0]} align="end">
                <Space size={16}>
                    <Button
                        size="large"
                        borderRadius={16}
                        onClick={closeThisModal}
                        disabled={loading}
                    >
                        Отмена
                    </Button>
                    <Button
                        size="large"
                        type="primary"
                        borderRadius={16}
                        onClick={() => setIsVisibleCertificateModal(true)}
                        disabled={loading}
                    >
                        Подтвердить заявку
                    </Button>
                </Space>
            </Box>
            <CertificateListModalWithTransactionData
                isModalVisible={isVisibleCertificateModal}
                setIsModalVisible={setIsVisibleCertificateModal}
                onSubscribe={onSubscribe}
                onSign={onSign}
            />
        </div>
    )
}
