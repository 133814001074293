import { FC } from 'react'
import { useSelector } from 'react-redux'

import { getPennies } from '@dltru/dfa-models'
import { BodyCheck } from '@dltru/dfa-ui'

import { dfaDetailsSelector } from '@store/dfa/details/selectors'
import { orderDetailsSelector } from '@store/orders/details/selectors'

interface IComponentProps {
    amount: number
    dynamicPrice?: number
    fee?: number
}
export const OrderInfo: FC<IComponentProps> = ({ amount, dynamicPrice, fee }) => {
    const commissionCents = useSelector(orderDetailsSelector.selectFee)
    const commission = fee !== undefined ? fee : commissionCents ? commissionCents / 100 : 0
    const dfaDetails = useSelector(dfaDetailsSelector.selectDfaDetails)

    if (!dfaDetails) {
        return null
    }

    const checkList = [
        {
            label: 'Тикер',
            Content: () => <strong>{dfaDetails.ticker_symbol}</strong>,
        },
    ]

    if (dynamicPrice) {
        checkList.push(
            {
                label: 'Сумма инвестиций',
                price: amount,
            },
            {
                label: 'Текущая цена ЦФА за ед.',
                price: dynamicPrice / 100,
            },
            {
                label: 'Количество, ед.',
                price: Math.trunc((amount * 100) / dynamicPrice),
                currency: ' ',
            },
            {
                label: 'Комиссия за приобретение ЦФА',
                price: commission,
            },
            {
                label: 'Итого',
                price:
                    commission +
                    Math.trunc((amount * 100) / dynamicPrice) *
                        (getPennies(dynamicPrice / 100) / 100),
            },
        )
    } else {
        checkList.push(
            {
                label: 'Цена',
                price: dfaDetails.price_per_dfa,
            },
            {
                label: 'Количество, ед.',
                price: amount / dfaDetails.price_per_dfa,
                currency: 'unit',
            },
            {
                label: 'Стоимость активов',
                price: amount,
            },
            {
                label: 'Комиссия за приобретение ЦФА',
                price: commission,
            },
            {
                label: 'Итого',
                price: commission + amount,
            },
        )
    }

    return (
        <div>
            {checkList.map((check) => (
                <BodyCheck {...check} key={check.label} />
            ))}
        </div>
    )
}
