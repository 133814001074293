import { FC, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import dayjs from 'dayjs'
import { InternalFieldData } from 'rc-field-form/lib/interface'

import {
    CollectType,
    ExternalRateIndex,
    PriceSource,
    RedemptionTypeDfa,
    ServiceCodeFee,
    SettlementsType,
    getPennies,
    stringToNumber,
} from '@dltru/dfa-models'
import {
    Alert,
    BodyCheck,
    BodyInputBlock,
    Box,
    CurrencyInput,
    Form,
    FormInstance,
    IntegerInput,
    TradeButton,
    TwoColumns,
    dfaTransferValidators,
} from '@dltru/dfa-ui'

import { authSelector } from '@store/auth/selectors'
import { dfaAvailableDealsSelector } from '@store/dfa/availableDeals/selectors'
import { dfaDetailsSelector } from '@store/dfa/details/selectors'
import { getCalculateFeeHelper } from '@store/helper'
import { moneySelector } from '@store/money/selectors'
import { profileSelector } from '@store/profiles/details/selectors'

import api from '@services/api'

import { AccountLimitBlock } from '@components/Modals/Orders/Trade/common/AccountLimitBlock'

import { TradeBuyingApproveModal } from '../../TradeBuyingApproveModal'

interface ITradeBuyingModalFormBody {
    isAmount: number
    setIsAmount: any
    changeBody: (target: string) => (e: React.SyntheticEvent) => void
    setIsModalVisible: (val: boolean) => void
    form: FormInstance<any>
}

export const TradeBuyingModalFormBody: FC<ITradeBuyingModalFormBody> = ({
    isAmount,
    setIsAmount,
    changeBody,
    setIsModalVisible,
    form,
}) => {
    const dfaDetails = useSelector(dfaDetailsSelector.selectDfaDetails)
    const moneyAccount = useSelector(moneySelector.selectAccount)
    const maxLimit = useSelector(profileSelector.selectLimitRublies)
    const user_id = useSelector(authSelector.selectUserUid)

    const [commission, setComission] = useState(0)
    const dfaAvailableDeals = useSelector(dfaAvailableDealsSelector.selectData)
    const [isErrorForm, setIsErrorForm] = useState(false)
    const [dynamicPrice, setDynamicPrice] = useState(0)
    const [isCardModalApproveVisible, setIsCardModalApproveVisible] = useState(false)
    const [countDfa, setCountDfa] = useState(0)
    const [targetAmount, setTargetAmount] = useState(0)

    const [hasAmount, setHasAmount] = useState<Boolean>(false)

    const [hasIsAmountFieldError, setHasIsAmountFieldError] = useState(false)

    const handleInputAmountChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value
        setHasAmount(Boolean(event.target.value))
        setIsAmount(stringToNumber(value))
        if (isDynamicPrice && value) {
            const count = Math.trunc(getPennies(value) / dynamicPrice)
            setCountDfa(count)
        }
    }
    const getDynamicPrice = async (index: ExternalRateIndex) => {
        const { data } = await api.lib.getExternalRates(index)
        setDynamicPrice(
            (data?.item?.rate ?? 0) * (((dfaDetails?.investment_spread ?? 0) + 100) / 100),
        )
    }
    const isDynamicPrice = dfaDetails.price_source_type === PriceSource.dynamic
    const showRedeemPrice =
        !isDynamicPrice && dfaDetails.redemption_type === RedemptionTypeDfa.fixed

    useEffect(() => {
        if (isDynamicPrice && dfaDetails.price_source) {
            getDynamicPrice(dfaDetails.price_source)
        }
    }, [isDynamicPrice, dfaDetails.investment_spread])

    const openCardModal = () => {
        setIsCardModalApproveVisible(true)
    }

    const onFieldsChange = (_, allFields: InternalFieldData[]) => {
        setIsErrorForm(allFields?.some(({ errors }) => errors?.length))
        const isAmountFieldError = allFields.find(
            (field: InternalFieldData) => field.name[0] === 'isAmount' && field.errors?.length,
        )
        setHasIsAmountFieldError(Boolean(isAmountFieldError))
    }

    useEffect(() => {
        if (dfaDetails.id) {
            const amount =
                dfaDetails.price_source_type === PriceSource.dynamic
                    ? countDfa * (dynamicPrice / 100)
                    : isAmount * dfaDetails.price_per_dfa
            setTargetAmount(getPennies(amount))
            if (!hasAmount) {
                setComission(0)
                return
            }
            getCalculateFeeHelper({
                service_code: ServiceCodeFee.emissionBuy,
                operation_amount: getPennies(amount),
                user_id,
                asset_id: dfaDetails.id,
                start_date: dayjs(dfaDetails.issue_start_date).toISOString(),
                end_date: dayjs(dfaDetails.issue_end_date).toISOString(),
            }).then((fee) => {
                setComission(fee.item.FeeAmount / 100)
            })
        }
    }, [
        isAmount,
        countDfa,
        dfaDetails.id,
        dfaDetails.price_per_dfa,
        dfaDetails.price_source_type,
        hasAmount,
    ])

    useEffect(() => {
        return () => {
            setComission(0)
        }
    }, [])

    const currentAccountMoney = (moneyAccount && moneyAccount.balance.available / 100) || 0
    const fullAmount =
        commission +
        (isDynamicPrice
            ? countDfa * (getPennies(dynamicPrice / 100) / 100)
            : isAmount * (dfaDetails.price_per_dfa ?? 0))

    const sum =
        dfaDetails.issue_settlements_type === SettlementsType.direct ? commission : fullAmount
    const isBalanceEnough = currentAccountMoney > sum || currentAccountMoney === sum

    const isDisabledBtn =
        !isAmount || isErrorForm || !isBalanceEnough || (isDynamicPrice && !countDfa)

    const collectTypeLabel =
        dfaDetails.payment_collect_type === CollectType.account
            ? 'На лицевой счёт'
            : 'На банковский счёт'
    return (
        <div style={{ background: '#ffffff' }}>
            <Form
                form={form}
                name="tradeModal"
                onFieldsChange={onFieldsChange}
                onFinish={openCardModal}
            >
                <TwoColumns gap="0 32px">
                    <BodyInputBlock
                        title={isDynamicPrice ? 'Сумма инвестиций' : 'Количество'}
                        explain={
                            isDynamicPrice || hasIsAmountFieldError ? (
                                <></>
                            ) : (
                                <>
                                    Доступно {dfaAvailableDeals?.rest_amount_dfa}
                                    <br />
                                    Объем выпуска {dfaAvailableDeals?.original_amount_dfa}
                                </>
                            )
                        }
                    >
                        {isDynamicPrice ? (
                            <CurrencyInput
                                name="isAmount"
                                prefix="₽"
                                rules={[
                                    {
                                        validator: dfaTransferValidators.maxDynamicSum({
                                            maxMoney:
                                                dfaDetails.issue_settlements_type !==
                                                SettlementsType.direct
                                                    ? currentAccountMoney
                                                    : undefined,
                                            maxLimit,
                                        }),
                                    },
                                ]}
                                onChange={handleInputAmountChange}
                                required
                            />
                        ) : (
                            <IntegerInput
                                name="isAmount"
                                rules={[
                                    {
                                        validator: dfaTransferValidators.maxSum({
                                            pricePerDFA: Number(dfaDetails.price_per_dfa),
                                            maxMoney:
                                                dfaDetails.issue_settlements_type !==
                                                SettlementsType.direct
                                                    ? currentAccountMoney
                                                    : undefined,
                                            maxCount: dfaAvailableDeals?.rest_amount_dfa ?? 0,
                                            maxLimit,
                                        }),
                                    },
                                ]}
                                onChange={handleInputAmountChange}
                                required
                            />
                        )}
                    </BodyInputBlock>
                    <BodyInputBlock
                        read
                        title={isDynamicPrice ? 'Текущая цена ЦФА за ед.' : 'Цена за ед.'}
                        value={
                            isDynamicPrice && dynamicPrice
                                ? dynamicPrice / 100
                                : dfaDetails.price_per_dfa
                        }
                        explain={
                            showRedeemPrice
                                ? `Цена погашения ${dfaDetails?.redeem_price_per_dfa?.toLocaleString()} ₽`
                                : undefined
                        }
                    />
                </TwoColumns>

                <Box padding={[8, 0, 0, 0]}>
                    {isDynamicPrice && dynamicPrice ? (
                        <BodyCheck
                            label="Количество, ед."
                            price={countDfa}
                            currency=" "
                            labelRight
                        />
                    ) : (
                        <BodyCheck
                            label="Стоимость активов"
                            price={isAmount * (dfaDetails.price_per_dfa ?? 0)}
                            labelRight
                        />
                    )}
                </Box>

                <Box>
                    <BodyCheck label="Комиссия за приобретение ЦФА" price={commission} labelRight />
                </Box>

                <Box>
                    <BodyCheck label="Итого" price={fullAmount} labelRight />
                </Box>

                <Box>
                    <BodyCheck
                        label="Способ расчётов"
                        text={
                            dfaDetails.issue_settlements_type === SettlementsType.direct
                                ? 'Вне Платформы'
                                : collectTypeLabel
                        }
                        labelRight
                    />
                </Box>

                {dfaDetails.issue_settlements_type === SettlementsType.direct && (
                    <Box padding={[8, 0]}>
                        <Alert
                            type="warning"
                            description="Стоимость активов необходимо оплатить эмитенту вне системы. Комиссия за приобретение ЦФА будет удержана с вашего лицевого счёта."
                            showIcon
                        />
                    </Box>
                )}

                <Box padding={[0, 0, 24, 0]}>
                    <AccountLimitBlock
                        actionAccount={changeBody('info')}
                        actionLimit={changeBody('limit')}
                    />
                </Box>

                <Box padding={[24, 0, 0, 0]} align="center">
                    <TradeButton
                        color="green"
                        onClick={form.submit}
                        disabled={isDisabledBtn}
                        label="Приобрести"
                    />
                </Box>
            </Form>

            <TradeBuyingApproveModal
                isModalVisible={isCardModalApproveVisible}
                setIsModalVisible={setIsCardModalApproveVisible}
                setIsParentModalVisible={setIsModalVisible}
                amount={isDynamicPrice && dynamicPrice ? isAmount : targetAmount / 100}
                fee={commission}
                dynamicPrice={dynamicPrice}
                isDynamicPrice={isDynamicPrice}
            />
        </div>
    )
}
